/*
 * Copyright (C) 2022 Net4Media Services SRL. - All Rights Reserved
 *
 * SA. Cătălin Florescu <catalin.florescu@net4media.net>
 * Snr.Soft.Eng. Nicolae Pleșa  <nicu.plesa@net4media.net>
 * Soft.Eng. Florin Mihăescu <florinmihaescu@net4media.net>
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

window.bootstrap  = require('bootstrap');
window.$ = window.jQuery = require('jquery');
window.Tagify = require('@yaireo/tagify');
window.DragSort = require('@yaireo/dragsort');
require( 'datatables.net-bs5' );
require( 'datatables.net-responsive-bs5' );

$(document).ready(function () {
    let offcanvasElementList = [].slice.call(document.querySelectorAll('.offcanvas'))
    offcanvasElementList.map(function (offcanvasEl) {
        return new bootstrap.Offcanvas(offcanvasEl)
    });
    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    });
    let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
    });
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    //document.addEventListener("contextmenu",(e=>e.preventDefault())),document.onkeydown=function(e){return 123!=e.keyCode&&((!e.ctrlKey||!e.shiftKey||73!=e.keyCode)&&((!e.ctrlKey||!e.shiftKey||74!=e.keyCode)&&((!e.ctrlKey||85!=e.keyCode)&&void 0)))};
});
